<template>
  <div class="select-time-page">
    <HomeIcon :showArrow="true" to="planos"/>
    <div class="container">
      <div class="loader-container" v-if="loading">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>
      <div v-else>
        <div v-if="showPaymentWarning" class="modal-overlay">
          <div class="modal-container">
            <h2>Atenção!</h2>
            <p>
              Você tem 5 minutos para concluir o pagamento. Após esse tempo, sua sessão será expirada e os horários selecionados ficarão indisponíveis para pagamento. Após concluir o pagamento, espere a página de confirmação ser carregada para ter seu horário confirmado.
            </p>
            <div class="button-group">
              <button @click="cancelPayment" class="modal-button cancel">Cancelar</button>
              <button @click="confirmPayment" class="modal-button confirm">Continuar</button>
            </div>
          </div>
        </div>
        <div v-if="hasSelectedDates">
          <h1 class="title">Selecione os horários do seu plano</h1>
          <div class="main-content">
            <p class="subtitle">
              <strong>O plano vai de:</strong> {{ formattedStartDate }} até {{ formattedEndDate }}
            </p>
            <p class="subtitle">
              <strong>Dia da semana escolhido: </strong> {{ selectedDayName }}
            </p>
            <h2 class="horariosDisponiveis">
              <strong>Horários disponíveis:</strong>
            </h2>

             <!-- Seção de legenda -->
             <div class="legend" v-if="isMensal">
              <div class="legend-item">
                <span class="dot fifty-reais"></span> R$200,00 (antes de 17:00)
              </div>
              <div class="legend-item">
                <span class="dot eighty-reais"></span> R$300,00 (a partir de 17:00)
              </div>
            </div>
            <div v-else class="legend2">
              <span class="dot eighty-reais"></span>R$780,00 para todos os horários
            </div>

            <div v-for="court in sortedCourts" :key="court.id" :class="{ disabled: isCourtDisabled(court.id) }" class="court">
              <h3>{{ court.nome }} - {{ court.patrocinador.nome }}</h3>
              <div class="times">
  <button
    v-for="time in availableTimes[court.id]"
    :key="time.hour"
    :class="{
      disabled: !time.available,
      selected: isSelected(court.id, time.hour),
      'before-five-mensal': time.hour < '17:00' && activePlanName === 1, // Cor específica para o plano mensal antes das 17:00
      'after-five': time.hour >= '17:00'
    }"
    @click="selectTime(court.id, time.hour)"
    :disabled="!time.available"
  >
    {{ time.hour }}
  </button>
</div>

            </div>
            <div class="descriptions">
              <p class="description">
                É possível selecionar mais de um horário de uma vez, mas todos os horários devem ser para a mesma quadra.
              </p>
              <p class="description">
                Cada horário selecionado corresponde a marcação para os quatro dias com desconto aplicado.
              </p>
            </div>
            <div class="total-price">
              Valor total: R$ {{ totalPrice.toFixed(2) }}
            </div>
            <button
              class="next-button"
              :class="{ clicked: nextButtonClicked }"
              @click="handleNextButtonClick()"
              @mousedown="nextButtonClicked = true"
              @mouseup="nextButtonClicked = false"
            >
              Próximo
            </button>
            <p v-if="showWarning" class="warning">Por favor, selecione um horário antes de prosseguir.</p>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </div>
        </div>
        <div v-else>
          <WarningData :rota="rota" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs';
import axios from 'axios';
import jwtDecode from 'jwt-decode'; // Importa jwt-decode para decodificar o token
import HomeIcon from '../../components/HomeIcon.vue';
import WarningData from '../../components/WarningData.vue';
import { loadStripe } from '@stripe/stripe-js';
import { useStore } from 'vuex';
import { AxiosError } from 'axios';

const stripePromise = loadStripe('pk_test_51Pw6F4KJmK61rZjcINJpjICXNPiw9me3GlzYgOnXSIMxIr678mVvFvy4vFDkpcORLeys7MPr8uxfll4cutJZ5z5q00izjyRGyN');

interface AvailableTime {
  hour: string;
  available: boolean;
}

interface Court {
  id: number;
  nome: string;
  patrocinador: {
    nome: string;
  };
}

interface DecodedToken {
  dates: string[];
  dayIndex: number;
  startDate: string;
  endDate: string;
  extraDate: string;
}

export default defineComponent({
  name: 'SelectTimeMensalPage',
  components: {
    HomeIcon,
    WarningData
  },
  setup() {
    const BASE_PRICE_PER_HOUR = 80;
    const PRICE_DISCOUNTED_PER_HOUR = 75;

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const courts = ref<Court[]>([]);
    const availableTimes = ref<{ [courtId: number]: AvailableTime[] }>({});
    const selectedTimes = ref<{ court: number; hour: string }[]>([]);
    const activeCourt = ref<number | null>(null);
    const loading = ref(true);
    const nextButtonClicked = ref(false);
    const showWarning = ref(false);
    const errorMessage = ref<string | null>(null);
    const activePlanName = ref<number | null>(1);
    const rota = ref<string>("calendar");
    const indexDia = ref<number | null>(null); 
    const showPaymentWarning = ref(false);
    const selectedDates = ref<string[]>([]);
    const startDate = ref<string | null>(null);
    const endDate = ref<string | null>(null);
    let isMensal = ref<boolean>(true)

    // Formatação das datas para exibição
    const formattedStartDate = computed(() => startDate.value ? dayjs(startDate.value).format('DD/MM/YYYY') : '');
    const formattedEndDate = computed(() => endDate.value ? dayjs(endDate.value).format('DD/MM/YYYY') : '');

    onMounted(() => {
      activePlanName.value = parseInt(route.query.planId as string, 10);
      const token = route.query.token as string;
      console.log(activePlanName.value)
      if(activePlanName.value !== 1){
        isMensal.value = false
      }

      if (token) {
        try {
          const decoded = jwtDecode<DecodedToken>(token);
          selectedDates.value = decoded.dates; // Lista de datas dentro do intervalo
          startDate.value = decoded.startDate; // Data de início
          endDate.value = decoded.endDate; // Data de término
          indexDia.value = decoded.dayIndex; 
        } catch (error) {
          console.error('Erro ao decodificar o token JWT:', error);
          router.push({ name: 'SelectDayPage' });
        }
      } else {
        router.push({ name: 'SelectDayPage' });
      }
    });

        // Adicione a função `handleNextButtonClick` no setup
const handleNextButtonClick = () => {
  if (selectedTimes.value.length === 0) {
    showWarning.value = true; // Exibe o aviso caso não haja horários selecionados
  } else {
    showPaymentWarning.value = true; // Exibe o modal de confirmação caso haja horários selecionados
  }
};

const cancelPayment = () => {
      showPaymentWarning.value = false;
    };

    const confirmPayment = () => {
      showPaymentWarning.value = false;
      goToNextStep();
    };

    const sortedCourts = computed(() => {
      return courts.value.slice().sort((a, b) => {
        const numberA = parseInt(a.nome.match(/\d+/)?.[0] || "0");
        const numberB = parseInt(b.nome.match(/\d+/)?.[0] || "0");
        return numberA - numberB;
      });
    });

    const hasSelectedDates = computed(() => selectedDates.value && selectedDates.value.length > 0);

    const selectedDayName = computed(() => {
      const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
      return selectedDates.value.length > 0 ? daysOfWeek[dayjs(selectedDates.value[0]).day()] : '';
    });

    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data;
      } catch (error) {
        console.error('Error fetching courts:', error);
      }
    };

    const fetchOccupiedTimes = async (dates: string[], courtIds: number[]): Promise<{ [courtId: number]: string[] }> => {
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/checkAvailability`, {
      dates,
      courtIds
    });

    return response.data.unavailableTimes;
  } catch (error) {
    console.error('Error fetching occupied times:', error);
    return {};
  }
};


// Função para extrair o número da quadra do nome
const extractCourtNumber = (courtName: string): number | null => {
  const match = courtName.match(/\d+/); // Encontra o primeiro número no nome da quadra
  return match ? parseInt(match[0]) : null;
};

const generateAvailableTimes = async () => {
  try {
    // Inicia o estado de carregamento
    loading.value = true;

    // Verifica se há datas e quadras selecionadas
    if (selectedDates.value.length === 0 || courts.value.length === 0) {
      availableTimes.value = {};
      loading.value = false;
      return;
    }

    // Obtém a primeira data selecionada para determinar o dia da semana
    const firstSelectedDate = selectedDates.value[0];
    const dayOfWeek = dayjs(firstSelectedDate).day(); // 0 = Domingo, 6 = Sábado

    // Precomputa todos os horários de 07:00 às 21:00
    const times: AvailableTime[] = [];
    for (let hour = 7; hour <= 21; hour++) {
      const hourStr = `${hour.toString().padStart(2, '0')}:00`;
      times.push({
        hour: hourStr,
        available: true,
      });
    }

    // Extrai todos os IDs das quadras
    const courtIds: number[] = courts.value.map((court: Court) => court.id);

    // Realiza uma única chamada para obter horários ocupados de todas as quadras
    const unavailableTimesMap = await fetchOccupiedTimes(selectedDates.value, courtIds);

    // Inicializa o objeto que armazenará os horários disponíveis por quadra
    const updatedAvailableTimes: { [courtId: number]: AvailableTime[] } = {};

    // Itera sobre cada quadra para determinar a disponibilidade dos horários
    for (const court of courts.value) {
      const courtNumber = extractCourtNumber(court.nome);
      const occupiedTimes = new Set(unavailableTimesMap[court.id] || []);

      // Aplica as regras de disponibilidade
      const courtTimes = times.map((time) => {
        let isAvailable = !occupiedTimes.has(time.hour);

        // 🔹 Fim de semana: Apenas Quadra 5 disponível
        if (dayOfWeek === 6 || dayOfWeek === 0) {
            isAvailable = false;
        } else {
          // 🔹 Dias úteis: Aplicando regras específicas

          // 🔸 Quadra 1
          if (courtNumber === 1) {
            if (dayOfWeek >= 1 && dayOfWeek <= 4 && time.hour >= "07:00" && time.hour <= "20:00") {
              isAvailable = false;
            } else if (dayOfWeek === 5 && time.hour >= "07:00" && time.hour <= "16:00") {
              isAvailable = false;
            }
          }

          // 🔸 Quadra 3
          if (courtNumber === 3) {
            if (dayOfWeek === 5 && time.hour >= "16:00" && time.hour <= "22:00") {
              isAvailable = false;
            }
            if (dayOfWeek === 3 && time.hour === "20:00") {
              isAvailable = false;
            }
          }

          // 🔸 Quadra 4
          if (courtNumber === 4) {
            if (dayOfWeek >= 1 && dayOfWeek <= 4 && time.hour >= "07:00" && time.hour <= "16:00") {
              isAvailable = false;
            }
            if (dayOfWeek === 5 && time.hour >= "07:00" && time.hour <= "22:00") {
              isAvailable = false;
            }
            if (dayOfWeek === 1 && time.hour === "18:00") {
              isAvailable = false;
            }
          }

          // 🔸 Quadra 2
          if (courtNumber === 2) {
            if (dayOfWeek === 2 && time.hour === "17:00") {
              isAvailable = false;
            }
            if (dayOfWeek === 5 && time.hour === "17:00") {
              isAvailable = false;
            }
          }

          // 🔸 Quadra 5
          if (courtNumber === 5) {
            if (dayOfWeek === 3 && time.hour === "19:00") {
              isAvailable = false;
            }
          }
        }

        return {
          ...time,
          available: isAvailable,
        };
      });

      // Salva os horários ajustados para a quadra atual
      updatedAvailableTimes[court.id] = courtTimes;
    }

    // Atualiza o estado reativo com os horários disponíveis
    availableTimes.value = updatedAvailableTimes;
  } catch (error) {
    console.error("Erro ao gerar horários disponíveis:", error);
  } finally {
    // Finaliza o estado de carregamento
    loading.value = false;
  }
};

    const selectTime = (court: number, hour: string) => {
      const index = selectedTimes.value.findIndex((t) => t.court === court && t.hour === hour);
      if (index === -1) {
        selectedTimes.value.push({ court, hour });
        activeCourt.value = court;
      } else {
        selectedTimes.value.splice(index, 1);
        if (selectedTimes.value.length === 0) {
          activeCourt.value = null;
        }
      }
    };

    const isSelected = (court: number, hour: string) => {
      return selectedTimes.value.some((t) => t.court === court && t.hour === hour);
    };

    const isCourtDisabled = (court: number) => {
      return activeCourt.value !== null && activeCourt.value !== court;
    };

    const goToNextStep = async () => {
      if (selectedTimes.value.length === 0) {
        showWarning.value = true;
        setTimeout(() => {
          showWarning.value = false;
        }, 3000);
        return;
      }

      loading.value = true;

      try {
        await store.dispatch("fetchUsuario");
        const userId = store.state.usuario?.id;

        if (!userId) {
          throw new Error("Usuário não está autenticado ou não foi carregado corretamente.");
        }

        const payload = {
          dates: selectedDates.value,
          entries: selectedTimes.value.map(t => ({ court: t.court, hour: t.hour })),
          total: totalPrice.value,
          userId: userId,
          plan: activePlanName.value,
          endDate: endDate.value,
          index: indexDia.value
        };

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session-plans`, payload);
        const sessionId = response.data.id;

        const stripe = await stripePromise;
        if (stripe) {
          stripe.redirectToCheckout({ sessionId });
        }
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.response) {
          errorMessage.value = `Conflitos de horários detectados`;
        } else {
          console.error("Error creating checkout session:", axiosError);
          errorMessage.value = 'Erro ao processar a sessão de pagamento.';
        }
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      if (hasSelectedDates.value) {
        await fetchCourts();
        await generateAvailableTimes();
      }
      loading.value = false;
    });

    const totalPrice = computed(() => {
  return selectedTimes.value.reduce((total, time) => {
    if (activePlanName.value === 2) {
      // Para o plano trimestral, R$780 para todos os horários
      return total + 780;
    } else if (activePlanName.value === 1) {
      // Para o plano mensal, R$200 antes das 17:00 e R$300 depois
      const isBeforeFive = time.hour < '17:00';
      const price = isBeforeFive ? 200 : 300;
      return total + price;
    } else {
      // Caso padrão (para outros planos ou erro)
      return total;
    }
  }, 0);
});




    return {
      handleNextButtonClick,
      hasSelectedDates,
      selectedDayName,
      availableTimes,
      courts,
      selectTime,
      isSelected,
      isCourtDisabled,
      goToNextStep,
      loading,
      nextButtonClicked,
      showWarning,
      errorMessage,
      totalPrice,
      activePlanName,
      sortedCourts,
      formattedStartDate,
      formattedEndDate,
      rota,
      cancelPayment,
      confirmPayment,
      showPaymentWarning,
      isMensal
    };
  },
});
</script>

<style scoped>

.legend2{
  margin-top:20px;
  font-size: 1rem;
}

.legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%; /* Define a forma redonda */
  display: inline-block;
  margin-right: 5px;
  flex-shrink: 0; /* Impede que a bolinha seja redimensionada */
}

.fifty-reais {
  background-color: #f01f84;
}

.eighty-reais {
  background-color: #ff5858;
}

.select-time-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.main-content {
  border-top: 5px solid #cd7f32;
  background: white;
  color: #333;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1350px;
}

.title{
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
}

.subtitle{
  font-size: 20px;
}

.court {
  margin-top: 10px;
  padding: 15px;
  background-color: #f7f7f7; /* Light background for courts section */
  border-radius: 8px;
}

.horariosDisponiveis{
  margin-top:10px;
  margin-bottom: -10px;
}

.court.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.times {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espaçamento entre os botões */
  width: 100%; /* Contêiner dos botões ocupa a largura total */
}

.times button {
  flex-grow: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% / 15 - 10px);
  box-sizing: border-box;
}

.times button.before-five {
  background-color: #f01f84 !important; /* Amarelo dourado para horários antes das 17:00 */
}

/* Estilo de botão ativo e hover */
.times button:hover:not(.disabled) {
  background-color: #f857a6;
}

.times button.disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.times button:not(.disabled) {
  background-color: #ff5858;
  color: white;
}

.times button.selected {
  background-color: #4300a2 !important;
  color: white;
}

.times button.selected:hover {
  background-color: #6600f5 !important;
}

/* Cor para horários antes das 17:00 no plano mensal */
.times button.before-five-mensal {
  background-color: #f01f84; /* Cor específica para o plano mensal de 7:00 às 16:59 */
}

/* Estilo padrão para outros horários */
.times button.after-five {
  background-color: #ff5858; /* Cor padrão para horários após as 17:00 */
}

.times button.selected {
  background-color: #4300a2 !important;
  color: white;
}

.description {
  color: #666;
}

.descriptions {
  margin-top: 20px;
}

.total-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  margin-top: 20px;
}

.next-button.clicked {
  background-color: #007bff;
  color: white;
}

.next-button.clicked:hover {
  background-color: #0056b3;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background: white;
  color: #ff5858;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

.error {
  color: #ff0000;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  background-color: #ffe6e6;
  border: 1px solid #ff0000;
  border-radius: 5px;
}

.warning {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column; /* Organiza os botões verticalmente */
  gap: 10px; /* Espaçamento entre os botões */
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%; /* Ocupa a largura completa do container */
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #ffffff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1285px) {
  .times button {
    width: calc(100% / 5 - 10px); /* Ocupa metade da largura em telas menores */
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 2rem;
  }
  .select-time-page {
  padding: 60px 20px
  }
}

/* Responsivo para telas pequenas (tablets e smartphones) */
@media (max-width: 768px) {
  .title {
    font-size: 1.8rem;
  }
  .times button {
    width: calc(100% / 3 - 10px); /* Ocupa metade da largura em telas menores */
  }
}

@media (max-width: 480px) {
 .times button {
    width: 100%; /* Cada botão ocupa toda a linha em telas muito pequenas */
  }
}

</style>
