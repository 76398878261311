<template>
  <div class="select-time-page">
    <HomeIcon :showArrow="true" to="/calendar" />
    <div class="container">
      <div class="loader-container" v-if="loading">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>
      <div v-else>
        <div v-if="showPaymentWarning" class="modal-overlay">
          <div class="modal-container">
            <h2>Atenção!</h2>
            <p>
              Você tem 5 minutos para concluir o pagamento. Após esse tempo, sua sessão será expirada e os horários selecionados ficarão indisponíveis para pagamento. Após concluir o pagamento, espere a página de confirmação ser carregada para ter seu horário confirmado.
            </p>
            <div class="button-group">
              <button @click="cancelPayment" class="modal-button cancel">Cancelar</button>
              <button @click="confirmPayment" class="modal-button confirm">Continuar</button>
            </div>
          </div>
        </div>

        <div :class="{ 'blurred': showPaymentWarning }">
          <h1 class="title">Selecione seus horários para {{ formattedDate }}</h1>
          <div class="main-content">
            <h2>Horários disponíveis:</h2>
            
            <!-- Seção de legenda -->
            <div class="legend">
              <div class="legend-item">
                <span class="dot fifty-reais"></span> R$50,00 (antes de 17:00)
              </div>
              <div class="legend-item">
                <span class="dot eighty-reais"></span> R$80,00 (a partir de 17:00)
              </div>
            </div>

            <div v-for="court in sortedCourts" :key="court.id" class="court">
              <h3>{{ court.nome }} - {{ court.patrocinador.nome }}</h3>
              <div class="times">
                <button
                  v-for="time in availableTimes[court.id]"
                  :key="time.hour"
                  :class="{
                    disabled: !time.available,
                    selected: isSelected(court.id, time.hour),
                    'before-five': time.hour < '17:00',
                  }"
                  @click="selectTime(court.id, time.hour)"
                  :disabled="!time.available"
                >
                  {{ time.hour }}
                </button>
              </div>
            </div>

            <p class="description">
              É possível selecionar mais de um horário de uma vez, em diferentes quadras.
            </p>
            <div class="total-price">
              Valor total: R$ {{ totalPrice.toFixed(2) }}
            </div>

            <div v-if="discountApplied" class="discount-message">
              <p>Desconto aplicado por ter plano: {{ activePlanName }}</p>
              <p>(somente aos horários de 17:00 às 21:00)</p>
            </div>

            <button
              class="next-button"
              :class="{ clicked: nextButtonClicked }"
              @click="handleNextButtonClick()"
              @mousedown="nextButtonClicked = true"
              @mouseup="nextButtonClicked = false"
            >
              Próximo
            </button>
            <p v-if="showWarning" class="warning">
              Por favor, selecione um horário antes de prosseguir.
            </p>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import dayjs from "dayjs";
import { loadStripe } from "@stripe/stripe-js";
import HomeIcon from "../../components/HomeIcon.vue";
import axios, { AxiosError } from "axios";
import jwtDecode from "jwt-decode";

const stripePromise = loadStripe("pk_test_51Pw6F4KJmK61rZjcINJpjICXNPiw9me3GlzYgOnXSIMxIr678mVvFvy4vFDkpcORLeys7MPr8uxfll4cutJZ5z5q00izjyRGyN");

interface AvailableTime {
  hour: string;
  available: boolean;
}

interface Court {
  id: number;
  nome: string;
  patrocinador: {
    nome: string;
  };
}

export default defineComponent({
  name: "SelectTimePage",
  components: { HomeIcon },
  setup() {
    const BASE_PRICE_PER_HOUR = 80;
    const DISCOUNTED_PRICE_BEFORE_17 = 50;

    const router = useRouter();
    const route = useRoute();
    const courts = ref<Court[]>([]);
    const availableTimes = ref<{ [courtId: number]: AvailableTime[] }>({});
    const selectedTimes = ref<{ court: number; hour: string }[]>([]);
    const loading = ref(true);
    const nextButtonClicked = ref(false);
    const showWarning = ref(false);
    const errorMessage = ref<string | null>(null);
    const activePlanName = ref<string | null>(null);
    const discountApplied = ref<boolean>(false);
    const selectedDate = ref<string | null>(null);
    const showPaymentWarning = ref(false);
    const user = ref<any>(null); // Dados do usuário

    // Função de decode do token
    const decodeToken = (token: string) => {
      try {
        const decoded = jwtDecode(token) as { date: string };
        return decoded.date;
      } catch (error) {
        console.error("Erro ao decodificar token JWT:", error);
        return null;
      }
    };

    // Data formatada
    const formattedDate = computed(() => {
      return selectedDate.value
        ? dayjs(selectedDate.value).format("DD/MM/YYYY")
        : "";
    });

    // Ordenar quadras
    const sortedCourts = computed(() => {
      return courts.value.slice().sort((a, b) => {
        const numberA = parseInt(a.nome.match(/\d+/)?.[0] || "0");
        const numberB = parseInt(b.nome.match(/\d+/)?.[0] || "0");
        return numberA - numberB;
      });
    });

    // Busca todas as quadras
    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data;
      } catch (error) {
        console.error("Error fetching courts:", error);
      }
    };

    // Nova função: busca **todos** os horários ocupados de todas as quadras para a data
    const fetchAllOccupiedTimes = async (date: string) => {
      try {
        // Supondo que esse endpoint retorne algo do tipo:
        // { "1": ["07:00","08:00"], "2": ["09:00"], "3": [], "4": ["18:00"], "5": [] }
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${date}`
        );
        return response.data; // Retorna o objeto
      } catch (error) {
        console.error("Erro ao buscar horários ocupados:", error);
        return {};
      }
    };

    // Gera os horários disponíveis, fazendo **apenas 1** requisição p/ pegar todos os ocupados
    const generateAvailableTimes = async () => {
  if (!selectedDate.value) return;

  const now = dayjs();
  const isToday = dayjs(selectedDate.value).isSame(now, "day");
  const dayOfWeek = dayjs(selectedDate.value).day();

  // 1) Busca todos os horários ocupados de todas as quadras de uma só vez
  const allOccupiedObj: Record<string, string[]> = await fetchAllOccupiedTimes(
    dayjs(selectedDate.value).format("YYYY-MM-DD")
  );

  // 2) Para cada quadra, geramos um array times: 07:00 -> 21:00
  // e aplicamos as regras de ocupação + custom constraints
  for (const court of courts.value) {
    const courtNumber = extractCourtNumber(court.nome);
    if (courtNumber === null) continue;

    // Inicializamos todos os horários
    const times: AvailableTime[] = [];
    for (let hour = 7; hour <= 21; hour++) {
      const timeInstance = dayjs(selectedDate.value)
        .hour(hour)
        .minute(0)
        .second(0);
      const hourStr = timeInstance.format("HH:mm");

      const isAfterNow = isToday ? timeInstance.isAfter(now) : true;
      times.push({
        hour: hourStr,
        available: isAfterNow,
      });
    }

    // 3) Pegamos os horários ocupados desse court
    const courtIdStr = court.id.toString();
    const occupiedTimes = allOccupiedObj[courtIdStr] || [];

    // Marcar como indisponível se estiver em `occupiedTimes`
    let courtTimes = times.map((t) => ({
      ...t,
      available: t.available && !occupiedTimes.includes(t.hour),
    }));

    // 4) Aplicar regras de bloqueio personalizadas
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      // Fim de semana => só quadra 5 é liberada
      if (courtNumber !== 5) {
        courtTimes = courtTimes.map((t) => ({ ...t, available: false }));
      }
    } else {
      // Regras de seg-sex

      // Quadra 1
      if (courtNumber === 1) {
        if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          // seg-qui: 07:00 - 20:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "20:00") && t.available,
          }));
        } else if (dayOfWeek === 5) {
          // sexta: 07:00 - 16:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "16:00") && t.available,
          }));
        }
      }

      // Quadra 4
      if (courtNumber === 4) {
        if (dayOfWeek >= 1 && dayOfWeek <= 4) {
          // seg-qui: 07:00 - 17:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "16:00") && t.available,
          }));
        } else if (dayOfWeek === 5) {
          // sexta: 07:00 - 22:00 indisponível
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available:
              !(t.hour >= "07:00" && t.hour <= "22:00") && t.available,
          }));
        }
      }

      // Quadra 3
      if (courtNumber === 3 && dayOfWeek === 5) {
        // sexta: 16:00 - 22:00 indisponível
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available:
            !(t.hour >= "16:00" && t.hour <= "22:00") && t.available,
        }));
      }

      // 📌 Novas regras de bloqueio específicas
      if (dayOfWeek === 1 && courtNumber === 4) {
        // Segunda-feira 18:00 - Quadra 4
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: !(t.hour === "18:00") && t.available,
        }));
      }

      if (dayOfWeek === 2 && courtNumber === 2) {
        // Terça-feira 17:00 - Quadra 2
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: !(t.hour === "17:00") && t.available,
        }));
      }

      if (dayOfWeek === 3) {
        if (courtNumber === 5) {
          // Quarta-feira 19:00 - Quadra 5
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available: !(t.hour === "19:00") && t.available,
          }));
        }
        if (courtNumber === 3) {
          // Quarta-feira 20:00 - Quadra 3
          courtTimes = courtTimes.map((t) => ({
            ...t,
            available: !(t.hour === "20:00") && t.available,
          }));
        }
      }

      if (dayOfWeek === 5 && courtNumber === 2) {
        // Sexta-feira 17:00 - Quadra 2
        courtTimes = courtTimes.map((t) => ({
          ...t,
          available: !(t.hour === "17:00") && t.available,
        }));
      }
    }

    // 5) Armazenar no `availableTimes` final
    availableTimes.value[court.id] = courtTimes;
  }

  loading.value = false;
};

    const extractCourtNumber = (courtName: string): number | null => {
      const match = courtName.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    };

    // Selecionar / deselecionar horário
    const selectTime = (court: number, hour: string) => {
      const index = selectedTimes.value.findIndex(
        (t) => t.court === court && t.hour === hour
      );
      if (index === -1) {
        selectedTimes.value.push({ court, hour });
      } else {
        selectedTimes.value.splice(index, 1);
      }
    };

    // Saber se já foi selecionado
    const isSelected = (court: number, hour: string) => {
      return selectedTimes.value.some((t) => t.court === court && t.hour === hour);
    };

    // Buscar dados do usuário
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/account`,
          { withCredentials: true }
        );
        user.value = response.data.user;
        console.log("Dados do usuário:", user.value);
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      }
    };

    // Buscar planos do usuário
    const fetchUserPlans = async () => {
      if (!user.value) return;

      try {
        const reservasResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/reservas/usuario/${user.value.id}`,
          { withCredentials: true }
        );
        const reservas = reservasResponse.data.reservas || [];

        const clubinhosResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/clubinhos/usuario/${user.value.id}`,
          { withCredentials: true }
        );
        const clubinhos = clubinhosResponse.data || [];

        let validTrimestral = false;
        let validMensal = false;
        let validClubinho = false;

        // Verificar reservas com plano
        for (const reserva of reservas) {
          if (reserva.idPlano === 2 && dayjs(reserva.dataFim).isAfter(dayjs())) {
            validTrimestral = true;
          } else if (
            reserva.idPlano === 1 &&
            dayjs(reserva.dataFim).isAfter(dayjs())
          ) {
            validMensal = true;
          }
        }

        // Verificar clubinhos
        for (const clubinho of clubinhos) {
          if (dayjs(clubinho.dataFim).isAfter(dayjs())) {
            validClubinho = true;
            break;
          }
        }

        // Aplica prioridade: se tiver clubinho, usa clubinho; senão trimestral; senão mensal
        if (validClubinho) {
          activePlanName.value = "Clubinho";
          discountApplied.value = true;
        } else if (validTrimestral) {
          activePlanName.value = "Trimestral";
          discountApplied.value = true;
        } else if (validMensal) {
          activePlanName.value = "Mensal";
          discountApplied.value = true;
        }
      } catch (error) {
        console.error("Erro ao obter planos e clubinhos do usuário:", error);
      }
    };

    // Ação do botão “Próximo”
    const handleNextButtonClick = () => {
      if (selectedTimes.value.length === 0) {
        showWarning.value = true;
      } else {
        showPaymentWarning.value = true;
      }
    };

    const cancelPayment = () => {
      showPaymentWarning.value = false;
    };

    const confirmPayment = () => {
      showPaymentWarning.value = false;
      goToNextStep();
    };

    // Ir para o checkout
    const goToNextStep = async () => {
      loading.value = true;
      const selectedEntries = selectedTimes.value.map((t) => ({
        court: t.court,
        hour: t.hour,
      }));
      const userId = user.value?.id;
      if (!userId) {
        errorMessage.value = "User ID is missing";
        loading.value = false;
        return;
      }
      const formattedFinal = [dayjs(selectedDate.value).format("YYYY-MM-DD")];
      const userPlan = activePlanName.value ? activePlanName.value : "none";

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session`,
          {
            dates: formattedFinal,
            entries: selectedEntries,
            userId,
            plan: userPlan,
          }
        );
        const sessionId = response.data.id;

        const stripe = await stripePromise;
        if (stripe) {
          stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe.js failed to load.");
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (
          axiosError.response &&
          axiosError.response.data &&
          (axiosError.response.data as any).error
        ) {
          errorMessage.value = (axiosError.response.data as any).error;
        } else {
          console.error("Error creating checkout session:", axiosError);
        }
      } finally {
        loading.value = false;
      }
    };

    // Cálculo do preço total
    const totalPrice = computed(() => {
      return selectedTimes.value.reduce((total, time) => {
        const isBeforeFive = time.hour < "17:00";
        let pricePerHour = isBeforeFive
          ? DISCOUNTED_PRICE_BEFORE_17
          : BASE_PRICE_PER_HOUR;

        if (!isBeforeFive && discountApplied.value) {
          if (activePlanName.value === "Mensal") {
            pricePerHour -= 5;
          } else if (
            activePlanName.value === "Trimestral" ||
            activePlanName.value === "Clubinho"
          ) {
            pricePerHour -= 15;
          }
        }
        return total + pricePerHour;
      }, 0);
    });

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (token) {
        const dateFromToken = decodeToken(token);
        if (dateFromToken) {
          selectedDate.value = dateFromToken;
          try {
            await fetchUserData();
            await fetchCourts();
            await fetchUserPlans();
            await generateAvailableTimes(); // faz só 1 requisição
          } catch (err) {
            console.error("Erro ao gerar horários:", err);
          } finally {
            loading.value = false;
          }
        } else {
          console.error("Data não encontrada no token.");
          loading.value = false;
        }
      } else {
        console.error("Token não encontrado.");
        loading.value = false;
      }
    });

    return {
      sortedCourts,
      availableTimes,
      selectedTimes,
      formattedDate,
      loading,
      nextButtonClicked,
      showWarning,
      errorMessage,
      discountApplied,
      activePlanName,
      showPaymentWarning,
      user,

      selectTime,
      isSelected,
      handleNextButtonClick,
      cancelPayment,
      confirmPayment,
      goToNextStep,
      totalPrice,
    };
  },
});
</script>

<style scoped>

.legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%; /* Define a forma redonda */
  display: inline-block;
  margin-right: 5px;
  flex-shrink: 0; /* Impede que a bolinha seja redimensionada */
}

.fifty-reais {
  background-color: #f01f84;
}

.eighty-reais {
  background-color: #ff5858;
}

.select-time-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.main-content {
  border-top: 5px solid #cd7f32;
  background: white;
  color: #333;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1350px;
}

.title{
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
}

.subtitle{
  font-size: 20px;
}

.court {
  margin-top: 10px;
  padding: 15px;
  background-color: #f7f7f7; /* Light background for courts section */
  border-radius: 8px;
}

.horariosDisponiveis{
  margin-top:10px;
  margin-bottom: -10px;
}

.court.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.times {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espaçamento entre os botões */
  width: 100%; /* Contêiner dos botões ocupa a largura total */
}

.times button {
  flex-grow: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% / 15 - 10px);
  box-sizing: border-box;
}

.times button.before-five {
  background-color: #f01f84 !important; /* Amarelo dourado para horários antes das 17:00 */
}

/* Estilo de botão ativo e hover */
.times button:hover:not(.disabled) {
  background-color: #f857a6;
}

.times button.disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.times button:not(.disabled) {
  background-color: #ff5858;
  color: white;
}

.times button.selected {
  background-color: #4300a2 !important;
  color: white;
}

.times button.selected:hover {
  background-color: #6600f5 !important;
}

.description {
  color: #666;
  margin-top: 20px;
}

.descriptions {
  margin-top: 20px;
}

.total-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  margin-top: 20px;
}

.next-button.clicked {
  background-color: #007bff;
  color: white;
}

.next-button.clicked:hover {
  background-color: #0056b3;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84  0%, #FF6A00 100%);
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background: white;
  color: #ff5858;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

.error {
  color: #ff0000;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  background-color: #ffe6e6;
  border: 1px solid #ff0000;
  border-radius: 5px;
}

.warning {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  width: 400px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-container h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.button-group {
  display: flex;
  flex-direction: column; /* Organiza os botões verticalmente */
  gap: 10px; /* Espaçamento entre os botões */
}

.modal-button {
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
  width: 100%; /* Ocupa a largura completa do container */
}

.modal-button.cancel {
  background-color: #ff5c5c;
  color: #fff;
}

.modal-button.cancel:hover {
  background-color: #e04848;
}

.modal-button.confirm {
  background-color: #6200ea;
  color: #ffffff;
}

.modal-button.confirm:hover {
  background-color: #4b00b2;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1285px) {
  .times button {
    width: calc(100% / 5 - 10px); /* Ocupa metade da largura em telas menores */
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 2rem;
  }
  .select-time-page {
  padding: 60px 20px
  }
}

/* Responsivo para telas pequenas (tablets e smartphones) */
@media (max-width: 768px) {
  .title {
    font-size: 1.8rem;
  }
  .times button {
    width: calc(100% / 3 - 10px); /* Ocupa metade da largura em telas menores */
  }
}

@media (max-width: 480px) {
  .times button {
    width: 100%; /* Cada botão ocupa toda a linha em telas muito pequenas */
  }
}

</style>
