<template>
    <div class="clubinho-page">
      <!-- Hero Section -->
      <header class="hero-section">
        <div class="overlay"></div>
        
        <!-- Inclusão do HomeIcon -->
        <HomeIcon :to="'/planos'" :showArrow="true" />
        
        <div class="hero-content">
          <h1 :class="['title', { animate: isAnimated }]">Descubra o Clubinho Sunset Arena</h1>
          <p :class="['subtitle', { animate: isAnimated }]">
            O espaço ideal para Beach Tennis! Benefícios exclusivos e liberdade total para jogar como e quando quiser.
          </p>
          <button
            class="cta-button"
            @click="scrollToBenefits"
            :class="{ animate: isAnimated }"
          >
            Junte-se Agora
          </button>
        </div>
      </header>
  
      <main class="content">
        <!-- Benefícios -->
        <section class="benefits" ref="benefitsSection">
          <h2 class="section-title">
            <font-awesome-icon icon="gift" class="section-icon" />
            Benefícios Exclusivos
          </h2>
          <div class="benefits-grid">
            <div class="benefit-card">
              <div class="icon">
                <font-awesome-icon icon="calendar-day" />
              </div>
              <h3>Acesso Livre</h3>
              <p>Jogue de Segunda a Sexta com liberdade total nos horários permitidos.</p>
            </div>
            <div class="benefit-card">
              <div class="icon">
                <font-awesome-icon icon="tag" />
              </div>
              <h3>Descontos</h3>
              <p>Economize nas reservas avulsas com preços especiais para membros.</p>
            </div>
            <div class="benefit-card">
              <div class="icon">
                <font-awesome-icon icon="umbrella-beach" />
              </div>
              <h3>Day Use</h3>
              <p>Curta finais de semana e feriados com acesso exclusivo às quadras.</p>
            </div>
            <div class="benefit-card">
              <div class="icon">
                <font-awesome-icon icon="trophy" />
              </div>
              <h3>Eventos</h3>
              <p>Participe de torneios, workshops e outras atividades incríveis.</p>
            </div>
          </div>
        </section>
  
        <!-- Planos -->
        <section class="plans">
          <h2 class="section-title sec2">
            <font-awesome-icon icon="money-check-alt" class="section-icon si2" />
            Escolha seu Pacote
          </h2>
          <div class="plans-grid">
            <div class="plan-card">
              <h3>Individual</h3>
              <p class="price">R$150/mês</p>
              <a href="/individual" class="cta-button-light">
                Assinar
            </a>
            </div>
            <div class="plan-card">
              <h3>Estudante</h3>
              <p class="price">R$120/mês</p>
              <p class="details">Documento necessário</p>
              <a href="/estudante" class="cta-button-light">
              Assinar
            </a>
            </div>
            <div class="plan-card">
              <h3>Família</h3>
              <p class="price">R$270/mês</p>
              <p class="details">Inclui casal e filhos até 12 anos</p>
              <a href="/familia" class="cta-button-light">
                Assinar
            </a>
            </div>
            <div class="plan-card">
              <h3>Alunos Sunset</h3>
              <p class="price">R$100/mês</p>
              <p class="details">Comprovação necessária</p>
              <a href="/aluno" class="cta-button-light">Assinar</a>
            </div>
          </div>
        </section>
      </main>
  
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import HomeIcon from '@/components/HomeIcon.vue';
  
  export default defineComponent({
    name: 'ClubinhoPage',
    components: {
      FontAwesomeIcon,
      HomeIcon
    },
    setup() {
      const benefitsSection = ref<HTMLElement | null>(null);
      const isAnimated = ref(false);
  
      const scrollToBenefits = () => {
        if (benefitsSection.value) {
          benefitsSection.value.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
      onMounted(() => {
        // Adiciona a animação após a montagem do componente
        setTimeout(() => {
          isAnimated.value = true;
          console.log('Animação iniciada: isAnimated =', isAnimated.value);
        }, 1); // Pequeno atraso para garantir que a transição seja aplicada corretamente
      });
  
      return {
        benefitsSection,
        scrollToBenefits,
        isAnimated,
      };
    },
  });
  </script>
  
  <style scoped>
  /* Variáveis CSS para facilitar a manutenção */
  :root {
    --primary-color: #f01f84;
    --secondary-color: #ff6a00;
    --background-color: #f0f0f0;
    --text-color: #333;
    --light-text-color: #666;
    --button-radius: 30px;
  }
  
  /* Estilo Geral */
  .clubinho-page {
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    background: var(--background-color);
    overflow-x: hidden;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('@/assets/quadras.jpg');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .hero-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 0 20px;
  }
  
  /* Título da Hero Section */
  .hero-content .title {
    text-transform: uppercase;
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.8s ease-out 0.8s, transform 0.8s ease-out, background 0.3s;
  }
  
  .hero-content .title.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Subtítulo da Hero Section */
  .hero-content .subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s ease-out 0.5s, transform 0.8s ease-out 0.5s;
  }
  
  .hero-content .subtitle.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Botão "Junte-se Agora" */
  .hero-content .cta-button {
    padding: 14px 28px;
    font-size: 1.2em;
    text-transform: uppercase;
    background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
    color: white;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(240, 31, 132, 0.3);
    opacity: 0;
    transform: translateY(20px);
    /* Removendo o atraso de transform */
    transition: opacity 0.8s ease-out 0.8s, transform 0.8s ease-out, background 0.3s;
    z-index: 3; /* Garantir que o botão fique acima de outros elementos */
  }
  
  .hero-content .cta-button.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .hero-content .cta-button:hover {
    background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
    transform: translateY(-3px);
    /* Opcional: adicionar transição mais rápida no hover */
    transition: transform 0.3s ease-out;
  }
  
  /* Benefícios */
  .benefits {
    padding: 80px 20px;
    background: #f0f0f0;
    color: #333;
  }
  
  .section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; /* Permite que os itens flexíveis quebrem para a próxima linha */
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
    position: relative;
    color: #f01f84;
  }
  
  .section-icon {
    margin-right: 10px;
    font-size: 1.5em;
  }

  .si2{
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.1));
  }
  
  .sec2 {
    color: white !important;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }
  
  .section-title::after {
    content: '';
    width: 60px;
    height: 4px;
    background: var(--primary-color);
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
    flex-basis: 100%; /* Faz com que o pseudo-elemento ocupe toda a largura disponível */
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .benefit-card {
    background: #ffffff;
    padding: 30px 20px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }
  
  .benefit-card .icon {
    font-size: 2.5rem;
    color: #f01f84;
    margin-bottom: 20px;
  }
  
  .benefit-card h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #f01f84;
    text-transform: uppercase;
  }
  
  .benefit-card p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Planos */
  .plans {
    padding: 80px 20px;
    background: linear-gradient(to top, #f01f84, #FF6A00);
    color: #333;
  }
  
  .plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .plan-card {
    background: white;
    border-radius: 15px;
    padding: 30px 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    /* Usando Flexbox para alinhar o botão consistentemente */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }
  
  .plan-card h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: var(--primary-color);
    text-transform: uppercase;
  }
  
  .plan-card .price {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .plan-card .details {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 20px;
  }
  
  .cta-button-light {
    text-decoration: none;
    text-transform: uppercase;
    background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background 0.3s, transform 0.3s;
    box-shadow: 0 5px 15px rgba(240, 31, 132, 0.3);
  }
  
  .cta-button-light:hover {
    transform: translateY(-3px);
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .hero-content .title {
      font-size: 2.5rem;
    }
  
    .hero-content .subtitle {
      font-size: 1.2rem;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .benefit-card h3,
    .plan-card h3 {
      font-size: 1.5rem;
    }
  
    .benefit-card p,
    .plan-card .details {
      font-size: 0.95rem;
    }
  }
  </style>
  