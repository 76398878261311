<template>
    <div class="admin-dashboard">
      <HomeIcon :showArrow="true" to="/" />
      <h2>Painel de Controle Administrativo</h2>
      <div class="button-group">
        <router-link to="/adm-horarios" class="dashboard-button">Gerenciar Horários</router-link>
        <router-link to="/adm-quadras" class="dashboard-button">Gerenciar Quadras</router-link>
        <router-link to="/adm-usuarios" class="dashboard-button">Criar e editar Usuários</router-link>
        <router-link to="/adm-patrocinadores" class="dashboard-button">Gerenciar Patrocinadores</router-link>
        <router-link to="/adm-choose-plan" class="dashboard-button">Criar Planos</router-link>
        <router-link to="/adm-professor" class="dashboard-button">Gerenciar Professores</router-link>
        <router-link to="/adm-search-clients" class="dashboard-button">Gerenciar Usuários</router-link>
        <router-link to="/adm-data" class="dashboard-button">Gerar relatórios</router-link>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import HomeIcon from '../../components/HomeIcon.vue';
  
  export default defineComponent({
    name: 'AdminDashboard',
    components: {
      HomeIcon
    }
  });
  </script>
  
  <style scoped>
.admin-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7 0%, #3B82F6 100%);
  text-align: center;
}

h2 {
  font-size: 2.4rem;
  color: #ffffff;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.button-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  max-width: 800px;
  width: 100%;
}

.dashboard-button {
  display: inline-block;
  padding: 18px 12px;
  font-size: 1.2rem;
  color: #ffffff;
  text-decoration: none;
  border-radius: 12px;
  font-weight: bold;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.dashboard-button:hover {
  transform: translateY(-4px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2));
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .button-group {
    grid-template-columns: 1fr;
  }
}
</style>
