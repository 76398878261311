<template>
  <div class="plans-page">
    <HomeIcon />
    <div class="container">
      <h1>ESCOLHA SEU PLANO</h1>
      <div class="plans">
        <div class="plan avulso">
          <div class="icon">
            <font-awesome-icon icon="calendar-day" size="3x" />
          </div>
          <h2>Avulso</h2>
          <p>
            Selecione um ou mais horários para reserva em qualquer data disponível nos próximos 7 dias.</p>
          <router-link :to="{ name: 'Calendar' }" class="button">Agendar</router-link>
        </div>
        <div class="plan mensal">
          <div class="icon" @click="selectPlan('mensal')">
            <font-awesome-icon icon="calendar-check" size="3x" />
          </div>
          <h2>Mensal</h2>
          <p>
            Garanta suas reservas semanais por um mês, com desconto em relação à reserva avulsa.
          </p>
          <router-link :to="{ name: 'SelectDayPage', query: { planId: 1 } }" class="button">Agendar</router-link>
        </div>
        <div class="plan trimestral">
          <div class="icon" @click="selectPlan('trimestral')">
            <font-awesome-icon icon="calendar-alt" size="3x" />
          </div>
          <h2>Trimestral</h2>
          <p>
            Garanta suas reservas semanais por três semanas, com desconto em relação à reserva avulsa e ao plano mensal.
          </p>
          <router-link :to="{ name: 'SelectDayPage', query: { planId: 2 } }" class="button">Agendar</router-link>
        </div>
        <div class="plan clube">
          <div class="icon">
            <font-awesome-icon icon="calendar-alt fa-solid fa-users" size="3x" />
          </div>
          <h2>Clubinho</h2>
          <p>
            Acesso livre a quadras específicas de segunda a sexta. Temos diversas opções de preço, para diferentes casos.
          </p>
          <router-link :to="{ name: 'Clubinho' }" class="button">Agendar</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HomeIcon from '../components/HomeIcon.vue';
import router from '@/router';

export default defineComponent({
  name: 'PlansPage',
  components: {
    HomeIcon,
  },
  setup() {
    const selectPlan = (plan: string) => {
      console.log(`Plano selecionado: ${plan}`);
      const planId = plan === 'mensal' ? 1 : 2;
      router.push(`/dias?planId=${planId}`); // Redireciona com o ID do plano
    };

    return {
      selectPlan,
    };
  },
});
</script>

<style scoped>
.plans-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.container {
  text-align: center;
  width: 100%;
  max-width: 1200px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.plans {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.plan {
  background: white;
  color: #333;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 250px;
  cursor: pointer;
  transition: transform 0.3s;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.avulso {
  border-top: 5px solid #9296cf;
}

.mensal {
  border-top: 5px solid #ff00d4;
}

.trimestral {
  border-top: 5px solid #ffd700;
}

.clube {
  border-top: 5px solid #00f7c2;
}

.icon {
  margin-bottom: 15px;
  color: #f01f84;
  cursor: pointer;
}

.plan:hover {
  transform: scale(1.05);
}

h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
  flex-grow: 1;
}

.button {
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s;
  align-self: center;
}

.button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

@media (max-width: 650px) {
  .plans {
    gap: 15px;
  }

  .plan {
    width: 90%;
    padding: 30px 15px;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .container {
    margin-top: 13px;
  }

  .plan {
    width: 100%;
  }

  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.85rem;
  }

  .button {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
}
</style>
