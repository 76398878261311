<template>
  <div class="privacy-policy-container">
    <h1>Política de Privacidade</h1>
    <div class="content">
      <section>
        <h2>1. Introdução</h2>
        <p>Respeitamos sua privacidade e estamos comprometidos em proteger suas informações pessoais. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e compartilhamos suas informações ao utilizar nossa plataforma de aluguel de quadras de areia.</p>
      </section>
      
      <section>
        <h2>2. Informações que Coletamos</h2>
        <p>Coletamos informações pessoais que você nos fornece diretamente ao se cadastrar ou usar nossa plataforma, incluindo:</p>
        <ul>
          <li><strong>Nome completo:</strong> Utilizado para identificar sua conta.</li>
          <li><strong>CPF:</strong> Necessário para fins de cadastro e emissão de notas fiscais, conforme exigido por lei.</li>
          <li><strong>Email:</strong> Utilizado para comunicação e recuperação de acesso.</li>
          <li><strong>Senha:</strong> Armazenada de forma criptografada usando técnicas seguras de hashing.</li>
          <li><strong>Telefone:</strong> Utilizado para contato em casos de necessidade sobre reservas.</li>
        </ul>
        <p>Além disso, podemos coletar automaticamente informações técnicas, como o endereço IP, tipo de navegador, dispositivo e dados de cookies para melhorar sua experiência e garantir a segurança na plataforma.</p>
      </section>

      <section>
        <h2>3. Uso de Informações</h2>
        <p>Usamos suas informações pessoais para as seguintes finalidades:</p>
        <ul>
          <li>Gerenciamento do seu cadastro e acesso à plataforma.</li>
          <li>Processamento e confirmação de reservas de quadras.</li>
          <li>Comunicação com você sobre atualizações, promoções ou mudanças nos termos.</li>
          <li>Atendimento a obrigações legais e regulamentares.</li>
          <li>Garantia de segurança e prevenção de fraudes.</li>
        </ul>
      </section>

      <section>
        <h2>4. Compartilhamento de Informações</h2>
        <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para:</p>
        <ul>
          <li>Processar pagamentos, usando a solução Stripe, que é uma plataforma de pagamento segura e certificada.</li>
          <li>Cumprir obrigações legais ou atender a ordens judiciais.</li>
          <li>Proteger nossos direitos e propriedade, ou a segurança de nossos usuários.</li>
        </ul>
        <p>Garantimos que seus dados não serão vendidos ou comercializados a terceiros para fins de marketing.</p>
      </section>

      <section>
        <h2>5. Uso de Cookies e Tecnologias de Rastreamento</h2>
        <p>Utilizamos cookies e tecnologias semelhantes para melhorar a experiência do usuário, como:</p>
        <ul>
          <li><strong>Cookies de Sessão:</strong> Usados para manter sua sessão ativa enquanto navega na plataforma.</li>
          <li><strong>Cookies de Funcionalidade:</strong> Usados para lembrar preferências e configurações do usuário.</li>
          <li><strong>Cookies de Segurança:</strong> Usados para garantir a proteção de suas informações durante a navegação.</li>
        </ul>
        <p>Você pode controlar e gerenciar o uso de cookies diretamente nas configurações do seu navegador, mas desativá-los pode afetar a funcionalidade da plataforma.</p>
      </section>

      <section>
        <h2>6. Segurança das Informações</h2>
        <p>Adotamos medidas técnicas e organizacionais robustas para proteger seus dados, como:</p>
        <ul>
          <li>Criptografia de dados sensíveis, como senhas, com hashing seguro.</li>
          <li>Utilização de HTTPS para comunicação segura entre o usuário e a plataforma.</li>
          <li>Autenticação de sessão e proteção contra ataques como CSRF e XSS.</li>
          <li>Monitoramento constante e auditorias para identificar e mitigar possíveis vulnerabilidades.</li>
        </ul>
      </section>

      <section>
        <h2>7. Direitos dos Usuários</h2>
        <p>De acordo com a LGPD, você tem os seguintes direitos em relação aos seus dados pessoais:</p>
        <ul>
          <li><strong>Acesso:</strong> Você pode solicitar informações sobre os dados que armazenamos sobre você.</li>
          <li><strong>Correção:</strong> Você pode solicitar a correção de dados imprecisos ou incompletos.</li>
          <li><strong>Exclusão:</strong> Você pode solicitar a exclusão de seus dados, exceto quando houver uma obrigação legal de retê-los.</li>
          <li><strong>Portabilidade:</strong> Você pode solicitar a transferência dos seus dados para outro fornecedor de serviços.</li>
          <li><strong>Revogação de Consentimento:</strong> Você pode retirar seu consentimento para o uso dos seus dados a qualquer momento.</li>
        </ul>
        <p>Para exercer esses direitos, entre em contato conosco por meio do email: sunsetarenainfo@gmail.com.</p>
      </section>

      <section>
        <h2>8. Retenção de Dados</h2>
        <p>Mantemos suas informações pelo tempo necessário para cumprir as finalidades descritas nesta Política, ou conforme exigido por lei. Após o término do relacionamento ou da finalidade específica, os dados serão anonimizados ou excluídos de forma segura.</p>
      </section>

      <section>
        <h2>9. Transferência Internacional de Dados</h2>
        <p>Em alguns casos, podemos transferir seus dados para fora do Brasil, por exemplo, ao usar provedores de serviços que possuem servidores em outros países. Garantimos que essas transferências são realizadas com um nível adequado de proteção, de acordo com a LGPD.</p>
      </section>

      <section>
        <h2>10. Alterações nesta Política de Privacidade</h2>
        <p>Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos qualquer alteração significativa por meio de avisos em nossa plataforma ou por email. Recomendamos que você revise esta Política regularmente para se manter informado sobre como protegemos seus dados.</p>
      </section>

      <section>
        <h2>11. Contato</h2>
        <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco pelo email: sunsetarenainfo@gmail.com.</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style scoped>
.privacy-policy-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

h1, h2 {
  color: #333;
}

p, ul {
  font-size: 16px;
  line-height: 1.6;
}

section {
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>
