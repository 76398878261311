<template>
  <div>
    <Header />
    <div class="plans-page">
      <div class="overlay">
        <div class="plans">
          <!-- Plano Mensal -->
          <div class="plan">
            <h2>Plano Mensal</h2>
            <p>Reserve um horário específico por semana, durante um mês. Aproveite um desconto em relação ao pagamento avulso.</p>
            <ul>
              <li>Reserva fixa semanal</li>
              <li>Desconto em relação ao pagamento avulso</li>
              <li>5 reais de desconto na compra de horários avulsos</li>
            </ul>
          </div>

          <!-- Plano Trimestral -->
          <div class="plan">
            <h2>Plano Trimestral</h2>
            <p>Reserve um horário específico por semana, por três meses. Desconto adicional em relação ao plano mensal e ao pagamento avulso.</p>
            <ul>
              <li>Reserva fixa semanal</li>
              <li>Desconto em relação ao plano mensal e avulso</li>
              <li>15 reais de desconto na compra de horários avulsos</li>
            </ul>
          </div>

          <!-- Avulso (Sem Plano) -->
          <div class="plan">
            <h2>Avulso (Sem Plano)</h2>
            <p>Pague por uso, ideal para quem joga ocasionalmente e prefere flexibilidade total. Sem compromisso de renovação.</p>
            <ul>
              <li>Acesso às quadras por hora</li>
              <li>Pagamento por uso</li>
            </ul>
          </div>

          <!-- Novo Plano: Clubinho -->
          <div class="plan">
            <h2>Clubinho</h2>
            <p>O Clubinho oferece acesso exclusivo e vantagens para nossos assinantes, proporcionando flexibilidade e benefícios adicionais.</p>
            
            <h3>Acesso Livre às Quadras:</h3>
            <ul>
              <li>
                <strong>Segunda a Quinta:</strong>
                <ul>
                  <li>8h às 17h: Quadra 4 + qualquer quadra disponível</li>
                  <li>17h às 22h: Agendamento com desconto</li>
                </ul>
              </li>
              <li>
                <strong>Sexta:</strong>
                <ul>
                  <li>8h às 16h: Quadra 4 + demais quadras livres</li>
                  <li>Day Use Incluso: Sexta à tarde até Domingo, exceto Quadra 5</li>
                </ul>
              </li>
            </ul>
            
            <h3>Descontos Exclusivos:</h3>
            <p>Assinantes pagam menos nas reservas de quadras avulsas!</p>
            <ul>
              <li>15 reais de desconto na compra de horários avulsos</li>
            </ul>
            
            <h3>Planos para Todos:</h3>
            <ul>
              <li><strong>Individual:</strong> R$150/mês</li>
              <li><strong>Estudante:</strong> R$120/mês - É necessário apresentar documento comprobatório da matrícula em alguma instituição de ensino</li>
              <li><strong>Família:</strong> R$270/mês - Inclui casal e filhos menores de 12 anos</li>
              <li><strong>Alunos Sunset:</strong> R$100/mês</li>
            </ul>
            
            <p>Lembre-se: Durante eventos especiais ou torneios, o acesso pode ser limitado.</p>
          </div>
        </div>
        <router-link to="/planos">
          <button>VER PLANOS</button>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default defineComponent({
  name: "PlansPage",
  components: {
    Header,
    Footer,
  },
});
</script>
<style scoped>
.plans-page {
  position: relative; 
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.plans-page::before {
  content: ''; /* Pseudo-elemento para o fundo fixo */
  position: fixed; /* Faz com que a imagem permaneça fixa no viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Imagem de fundo */
  background-image: url('@/assets/quadras.jpg'); /* Substitua pelo caminho correto */
  background-size: cover; /* Faz a imagem cobrir toda a área */
  background-position: center center; /* Centraliza a imagem */
  background-repeat: no-repeat;

  z-index: -1; /* Coloca a imagem atrás de todo o conteúdo */
}

.overlay {
  position: relative; /* Define o overlay para o conteúdo */
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  /* Fundo semi-transparente para contraste */
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  position: relative; /* Mantém o footer separado do pseudo-elemento fixo */
  background: white; /* Define um fundo branco para o footer */
  width: 100%;
  padding: 20px 0;
  text-align: center;
  color: black; /* Define uma cor contrastante para o texto */
  z-index: 1; /* Garante que o footer fique acima do pseudo-elemento */
}

.plans {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 700px;
  width: 100%;
  z-index: 1; /* Garante que o conteúdo fique acima do fundo */
}

.plan {
  background: linear-gradient(to top, #f01f84, #FF6A00);
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  text-align: left;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.plan:hover {
  transform: translateY(-5px);
}

.plan h2 {
  margin-bottom: 10px;
  font-size: 1.8em;
}

.plan p {
  margin-bottom: 12px;
  font-size: 1.1em;
}

.plan ul {
  list-style-type: none;
  padding: 0;
}

.plan ul li {
  background: rgba(255, 255, 255, 0.3);
  margin: 8px 0;
  padding: 10px;
  border-radius: 8px;
  color: white;
}

button {
  margin-top: 25px;
  padding: 12px 25px;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
}

button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  transform: scale(1.05);
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  .plans-page::before {
    position: fixed; /* Mantém a imagem fixa no mobile */
    background-size: cover; /* Faz a imagem cobrir o viewport */
    background-position: center center; /* Centraliza a imagem */
  }

  .plans{
    padding: 25px;
  }

  .overlay {
    padding: 20px 10px; /* Reduz o padding em telas menores */
  }

  .plan h2 {
    font-size: 1.6em; /* Ajusta o tamanho dos títulos */
  }

  .plan p {
    font-size: 1em; /* Reduz o tamanho do texto */
  }

  button {
    font-size: 1em; /* Ajusta o tamanho dos botões */
    padding: 10px 20px;
    margin-top: -80px;
  }
}

@media (max-width: 480px) {
  .plans-page::before {
    position: fixed; /* Mantém a imagem fixa no mobile */
    background-size: cover; /* Faz a imagem cobrir o viewport */
    background-position: center; /* Centraliza a imagem */
  }

  .plan h2 {
    font-size: 1.4em;
  }

  .plan p {
    font-size: 0.9em;
  }

  button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}
</style>
