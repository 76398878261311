<template>
  <div class="cancel-page">
    <div class="content-container">
      <h1 class="title">Pagamento Cancelado</h1>
      <!-- Mensagem personalizada baseada no motivo do cancelamento -->
      <p class="message">{{ message }}</p>
      <router-link to="/" class="home-link">Voltar à página inicial</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CancelPage',
  setup() {
    const route = useRoute();
    const reason = route.query.reason;

    const message = reason === 'conflito'
      ? 'Esse horário já está reservado. Por favor, escolha outro horário.'
      : 'Seu pagamento foi cancelado. Se você tiver alguma dúvida, entre em contato com o suporte.';

    return {
      message
    };
  }
});
</script>

<style scoped>
.cancel-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  padding: 20px;
  text-align: center;
  color: #ffffff;
}

.content-container {
  background: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  width: 100%;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  /* pequena animação de entrada (opcional) */
  animation: fadeInUp 0.6s ease;
}

.title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
  text-transform: uppercase;
}

.message {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #555;
}

.home-link {
  display: inline-block;
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 6px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.home-link:hover {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  transform: scale(1.03);
}

/* Animação de entrada suave */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
