<template>
  <div class="success-page">
    <div v-if="loading" class="loading-container">
      <div class="loading-animation">
        <h1>Carregando detalhes...</h1>
      </div>
    </div>

    <div v-else>
      <!-- Se for reembolsado, mostra mensagem especial -->
      <div v-if="refunded" class="refunded-container">
        <h1>Pagamento Reembolsado</h1>
        <p>
          Infelizmente ocorreu um conflito ou problema na reserva, e seu pagamento foi reembolsado.
          Por favor, selecione outro horário ou tente novamente.
        </p>
      </div>
      <!-- Se der qualquer outro erro, mostra o container de erro -->
      <div v-else-if="error" class="error-container">
        <h1>Erro</h1>
        <p>{{ error }}</p>
      </div>
      <!-- Caso tudo certo, exibe os detalhes -->
      <div v-else>
        <!-- Card para Planos, Reservas, Renovação e Clubinho -->
        <div class="details-card">
          <div class="details-header">
            <h2>SUNSET ARENA</h2>
          </div>
          <div class="badge-container">
            <div 
              :class="{
                'badge-plan': sessionDetails?.type === 'plano',
                'badge-reservation': sessionDetails?.type === 'reserva',
                'badge-renovation': sessionDetails?.type === 'renovacao',
                'badge-clubinho': sessionDetails?.type === 'clubinho'
              }"
            >
              <strong>
                {{
                  sessionDetails?.type === 'plano'
                    ? 'Plano'
                    : sessionDetails?.type === 'renovacao'
                    ? 'Renovação'
                    : sessionDetails?.type === 'clubinho'
                    ? 'Clubinho'
                    : 'Avulso'
                }}
              </strong>
            </div>
          </div>

          <!-- Design para Clubinho -->
          <div v-if="sessionDetails?.type === 'clubinho'" class="clubinho-body">
            <div class="plan-item">
              <p><strong>Tipo do Plano:</strong> {{ sessionDetails?.plan }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Data de Início:</strong> {{ sessionDetails?.dataInicio }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Data de Fim:</strong> {{ sessionDetails?.dataFim }}</p>
            </div>
          </div>

          <!-- Design para outros tipos -->
          <div v-else-if="sessionDetails?.type === 'plano'" class="plan-body">
            <div class="plan-item">
              <p><strong>Tipo do Plano:</strong> {{ sessionDetails?.plan }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Data de Início:</strong> {{ formatDate(sessionDetails?.startDate) }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Data de Fim:</strong> {{ formatDate(sessionDetails?.endDate) }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Quadra:</strong> {{ sessionDetails?.quadra }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Horários:</strong></p>
              <div class="horarios-grid">
                <span
                  v-for="(hour, index) in sessionDetails?.horarios || []"
                  :key="index"
                  class="horario-item"
                >
                  {{ hour }}
                </span>
              </div>
            </div>
          </div>

          <!-- Design para Renovação -->
          <div v-else-if="sessionDetails?.type === 'renovacao'" class="renewal-body">
            <div class="plan-item">
              <p><strong>Tipo do Plano:</strong> {{ sessionDetails?.plan }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Data de Início da Reserva Original:</strong> {{ formatDate(sessionDetails?.dataInicio) }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Data de Fim Inicial:</strong> {{ formatDate(sessionDetails?.firstEndDate) }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Nova Data de Fim:</strong> {{ formatDate(sessionDetails?.endDate) }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Quadra:</strong> {{ sessionDetails?.quadra }}</p>
            </div>
            <div class="plan-item">
              <p><strong>Horários:</strong></p>
              <div class="horarios-grid">
                <span
                  v-for="(hour, index) in sessionDetails?.horarios || []"
                  :key="index"
                  class="horario-item"
                >
                  {{ hour }}
                </span>
              </div>
            </div>
          </div>

          <!-- Design para Reservas (Avulsas) -->
          <div v-else class="ticket-body">
            <div class="ticket-item">
              <p><strong>Data:</strong> {{ formatDate(sessionDetails?.dates?.[0]) }}</p>
            </div>
            <div class="ticket-item">
              <p><strong>Quadras e Horários:</strong></p>
              <div class="horarios-grid">
                <span
                  v-for="(entry, index) in sessionDetails?.entries || []"
                  :key="index"
                  class="horario-item"
                >
                  {{ entry.courtName }} às {{ entry.hour }}
                </span>
              </div>
            </div>
          </div>

          <!-- Mensagem de Agradecimento -->
          <div class="thank-you-message">
            <p>Obrigado por adquirir conosco! Aproveite sua experiência.</p>
          </div>
        </div>
      </div>
      <button @click="goToHome" class="home-button">Voltar para a Home</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'SuccessPage',
  setup() {
    const router = useRouter();
    const loading = ref(true);
    const error = ref<string | null>(null);
    const sessionDetails = ref<any>(null);
    const refunded = ref<boolean>(false);

    const fetchSessionDetails = async (sessionId: string) => {
      try {
        const accountResponse = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/account`, {
          withCredentials: true,
        });

        const userId = accountResponse.data?.user?.id;
        if (!userId) {
          throw new Error('Usuário não autenticado.');
        }

        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/stripe/get-session-details`,
          { sessionId, userId },
          { withCredentials: true }
        );

        if (response.status === 409) {
          refunded.value = true;
          return;
        }

        if (response.status !== 200) {
          throw new Error(response.data.error || 'Erro ao carregar detalhes.');
        }

        if (response.data.success) {
          sessionDetails.value = response.data.metadata;
        } else {
          throw new Error(response.data.error || 'Erro ao carregar detalhes.');
        }
      } catch (err: any) {
        if (err.response && err.response.status === 409) {
          refunded.value = true;
        } else {
          error.value = 'Erro ao carregar os detalhes.';
        }
        console.error('Erro ao buscar detalhes:', err);
      } finally {
        loading.value = false;
      }
    };

    const formatDate = (date: string | undefined): string => {
      return date ? dayjs(date).format('DD/MM/YYYY') : 'Data Inválida';
    };

    onMounted(() => {
      const sessionId = new URLSearchParams(window.location.search).get('session_id');
      if (sessionId) {
        fetchSessionDetails(sessionId);
      } else {
        error.value = 'O ID da sessão está ausente.';
        loading.value = false;
      }
    });

    const goToHome = () => {
      router.push('/');
    };

    return {
      loading,
      error,
      sessionDetails,
      refunded,
      goToHome,
      formatDate,
    };
  },
});
</script>

<style scoped>
.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-animation {
  text-align: center;
  animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.details-card {
  width: 400px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
}

.details-header {
  background: #FF6A00;
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
}

.badge-container {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 10px;
}

.badge-plan {
  background: #d6336c;
  color: white;
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
}

.badge-reservation {
  background: #0081a7;
  color: white;
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
}

.badge-renovation {
  background: #28a745;
  color: white;
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
}

.badge-clubinho {
  background: #ff9900;
  color: white;
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
}

.plan-body,
.ticket-body,
.renewal-body,
.clubinho-body {
  padding: 10px;
  text-align: left;
}

.plan-item,
.ticket-item {
  margin-bottom: 10px;
  color: black;
}

.horarios-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.horario-item {
  background: #f7f7f7;
  color: #FF6A00;
  padding: 8px 12px;
  border-radius: 10px;
  font-weight: bold;
}

.thank-you-message {
  background: #f7f7f7;
  padding: 10px;
  margin-top: 15px;
  border-radius: 10px;
  color: #666;
  font-size: 0.9rem;
  text-align: center;
}

.error-container {
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 10px;
}

.refunded-container {
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid #fff;
  padding: 30px;
  border-radius: 15px;
  max-width: 400px;
  margin-bottom: 20px;
}

.refunded-container h1 {
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}

.home-button {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: white;
  color: #FF6A00;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .details-header {
    font-size: 1.2rem;
  }
  .horario-item {
    flex: 1 0 45%;
  }
}

@media (max-width: 480px) {
  .details-header {
    font-size: 1rem;
  }
  .horario-item {
    flex: 1 0 100%;
  }
}
</style>
