<template>
  <div class="terms-of-service-container">
    <h1>Termos de Uso</h1>
    <div class="content">
      <section>
        <h2>1. Introdução</h2>
        <p>Estes Termos de Uso regem o uso de nossa plataforma para aluguel de quadras de areia. Ao acessar ou usar nossos serviços, você concorda em cumprir estes Termos. Caso não concorde com algum dos termos, por favor, não utilize nossos serviços.</p>
      </section>
      <section>
        <h2>2. Aceitação dos Termos</h2>
        <p>Ao se cadastrar e utilizar a nossa plataforma, você declara que leu, entendeu e concorda com estes Termos de Uso e com a nossa Política de Privacidade.</p>
      </section>
      <section>
        <h2>3. Uso do Serviço</h2>
        <p>Você concorda em utilizar nosso serviço de forma responsável e em conformidade com todas as leis e regulamentos aplicáveis. É estritamente proibido usar a plataforma para qualquer atividade ilegal, não autorizada ou que viole direitos de terceiros.</p>
      </section>
      <section>
        <h2>4. Contas de Usuário</h2>
        <p>Ao criar uma conta, você se compromete a fornecer informações precisas e atualizadas. Você é responsável por manter a segurança de suas credenciais de login, incluindo senha, e por todas as atividades realizadas em sua conta. Em caso de suspeita de uso não autorizado, entre em contato imediatamente.</p>
      </section>
      <section>
        <h2>5. Processamento de Pagamentos</h2>
        <p>Os pagamentos realizados em nossa plataforma são processados por meio do Stripe, uma solução segura e confiável. Não armazenamos informações sensíveis de pagamento em nossos servidores. Para mais detalhes, consulte a <a href="/privacy">Política de Privacidade</a>.</p>
      </section>
      <section>
        <h2>6. Política de Reembolso</h2>
        <p>Em caso de cancelamento ou reembolso, consulte nossa política específica disponível na seção de reembolsos de nosso site ou entre em contato com o suporte para mais informações. O reembolso está sujeito a condições específicas, dependendo do prazo de antecedência do cancelamento.</p>
      </section>
      <section>
        <h2>7. Limitações de Responsabilidade</h2>
        <p>Nosso serviço é fornecido "como está" e "conforme disponível". Não garantimos que o serviço atenderá às suas expectativas ou que será ininterrupto ou livre de erros. Em nenhuma hipótese seremos responsáveis por quaisquer danos, incluindo lucros cessantes, perda de dados ou outros danos indiretos.</p>
      </section>
      <section>
        <h2>8. Modificações dos Termos</h2>
        <p>Reservamo-nos o direito de modificar estes Termos a qualquer momento. Notificaremos os usuários de mudanças significativas por meio de avisos em nossa plataforma ou por email. O uso continuado do serviço após as alterações implica a aceitação dos novos Termos.</p>
      </section>
      <section>
        <h2>9. Contato</h2>
        <p>Se você tiver dúvidas sobre estes Termos de Uso, entre em contato conosco através do email: sunsetarenainfo@gmail.com.</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService'
};
</script>

<style scoped>
.terms-of-service-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

h1, h2 {
  color: #333;
}

p, ul {
  font-size: 16px;
  line-height: 1.6;
}

section {
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>
