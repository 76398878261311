<template>
  <div class="admin-reports-page">
    <HomeIcon :showArrow="true" to="adm-dashboard" />
    <div class="container">
      <h3>📊 Gerar Relatórios</h3>
      
      <div class="options">
        <h4>Selecione o tipo de relatório:</h4>
        <select v-model="selectedReport">
          <option value="usuarios">Usuários</option>
          <option value="financeiro">Financeiro</option>
          <option value="reservas">Reservas</option>
          <option value="horarios">Horários Ocupados</option>
        </select>
      </div>

      <div class="generate-section">
        <button @click="generateReport" :disabled="loading">
          <span v-if="loading">⏳ Gerando...</span>
          <span v-else>📥 Baixar Relatório</span>
        </button>
      </div>

      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Processando relatório...</p>
      </div>

      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import HomeIcon from "@/components/HomeIcon.vue";

export default defineComponent({
  name: "AdminReports",
  components: { HomeIcon },
  setup() {
    const selectedReport = ref("usuarios");
    const loading = ref(false);
    const errorMessage = ref("");

    const generateReport = async () => {
      loading.value = true;
      errorMessage.value = "";

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/relatorios/${selectedReport.value}`,
          { responseType: "blob", withCredentials: true }
        );

        // Criando um objeto Blob para o arquivo
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Criando link para download automático
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedReport.value}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Erro ao gerar relatório:", error);
        errorMessage.value = "Erro ao gerar relatório. Tente novamente.";
      } finally {
        loading.value = false;
      }
    };

    return { selectedReport, generateReport, loading, errorMessage };
  },
});
</script>

<style scoped>
.admin-reports-page {
  padding: 50px;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7 0%, #3B82F6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #222;
  font-weight: bold;
}

.options {
  margin-bottom: 25px;
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 8px;
  color: #555;
  font-weight: 600;
}

select {
  width: 100%;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 1.1rem;
  background: white;
  transition: border-color 0.3s;
}

select:focus {
  border-color: #2563eb;
  outline: none;
}

.generate-section {
  margin-top: 20px;
}

button {
  padding: 12px 25px;
  font-size: 1.1rem;
  font-weight: bold;
  background: linear-gradient(135deg, #2563eb 0%, #1e40af 100%);
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s, transform 0.2s;
}

button:hover {
  background: linear-gradient(135deg, #1e40af 0%, #2563eb 100%);
  transform: scale(1.05);
}

button:disabled {
  background: #aaa;
  cursor: not-allowed;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.loader {
  width: 45px;
  height: 45px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #2563eb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
