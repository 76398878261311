<template>
  <div class="select-plan-page">
    <HomeIcon :showArrow="true" to="adm-dashboard"/>
    <h1>Escolha o Plano</h1>
    <div class="container">
      <!-- Opções de planos -->
      <button @click="selectPlan(1)" class="plan-button">
        Plano Mensal
      </button>
      <button @click="selectPlan(2)" class="plan-button">
        Plano Trimestral
      </button>
      <button @click="selectPlan(3)" class="plan-button">
        Clubinho
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import HomeIcon from '../../../components/HomeIcon.vue';

export default defineComponent({
  name: 'SelectPlanPage',
  components: {
    HomeIcon,
  },
  setup() {
    const router = useRouter();

    // Função para selecionar o plano e navegar para a página correspondente
    const selectPlan = (planId: number) => {
      if(planId === 3){
        router.push({
          name: 'AdmCreateClubinho'
        });
      } else {
        router.push({
          name: 'AdmCreatePlans',
          query: { planId },
        });
      }
    };

    return {
      selectPlan,
    };
  },
});
</script>

<style scoped>
.select-plan-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  color: white;
  padding: 40px 20px;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.4);
}

.container {
  background: white;
  color: #333;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.plan-button {
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10px;
  transition: background 0.3s, transform 0.2s;
  width: 100%;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.plan-button:hover {
  background: linear-gradient(135deg, #3B82F6, #6EE7B7);
  transform: scale(1.05);
}

@media (max-width: 600px) {
  h1 {
    font-size: 2.5rem;
  }
  .plan-button {
    font-size: 1.1rem;
    padding: 12px 24px;
  }
}
</style>
