<template>
  <div class="select-day-page">
    <HomeIcon :showArrow="true" to="adm-choose-plan"/>
    <h1>Escolha o dia da semana</h1>
    <div class="container">
      <select v-model="selectedDay" class="dropdown">
        <option disabled value="-1">Selecione um dia</option>
        <option v-for="(day, index) in daysOfWeek" :key="index" :value="index">
          {{ day }}
        </option>
      </select>
      <button @click="goToSelectTime" class="next-button">Próximo</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios'; 
import HomeIcon from '../../../components/HomeIcon.vue';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AdmCreatePlans',
  components: {
    HomeIcon
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const selectedDay = ref<number | null>(-1);

    const getNextOccurrences = (dayIndex: number, startDate: string, durationInMonths: number): string[] => {
      const start = dayjs(startDate);
      const end = start.add(durationInMonths, 'month').add(1, 'week');
      const occurrences: string[] = [];
      let nextOccurrence = start;

      while (nextOccurrence.isBefore(end) || nextOccurrence.isSame(end, 'day')) {
        occurrences.push(nextOccurrence.format('YYYY-MM-DD'));
        nextOccurrence = nextOccurrence.add(1, 'week');
      }

      return occurrences;
    };

    const goToSelectTime = async () => {
      const planoId = route.query.planId ? parseInt(route.query.planId as string) : null;
  
      if (!planoId) {
        alert('Por favor, selecione um plano primeiro.');
        router.push({ name: 'AdmChoosePlan' });
        return;
      }

      if (selectedDay.value !== null && selectedDay.value !== -1) {
        const durationInMonths = planoId === 1 ? 1 : 3;
        const today = dayjs();
        let firstOccurrence = today.day(selectedDay.value);
        if (firstOccurrence.isBefore(today) || firstOccurrence.isSame(today)) {
          firstOccurrence = firstOccurrence.add(1, 'week');
        }

        const nextOccurrences = getNextOccurrences(selectedDay.value, firstOccurrence.format('YYYY-MM-DD'), durationInMonths);
        const startDate = nextOccurrences[0];
        const endDate = dayjs(startDate).add(durationInMonths, 'month').format('YYYY-MM-DD');

        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-token-mensal`, {
            dates: nextOccurrences,
            dayIndex: selectedDay.value,
            startDate: startDate,
            endDate: endDate,
            extraDate: nextOccurrences[nextOccurrences.length - 1],
          });

          const token = response.data.token;

          router.push({
            name: 'AdmFinalPlans',
            query: {
              token,
              idPlano: planoId,
            },
          });
        } catch (error) {
          console.error('Erro ao gerar o token JWT:', error);
          alert('Erro ao gerar o token. Tente novamente mais tarde.');
        }
      } else {
        alert('Por favor, selecione um dia da semana.');
      }
    };

    return {
      daysOfWeek,
      selectedDay,
      goToSelectTime,
    };
  }
});
</script>

<style scoped>
.select-day-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Novo gradiente azul */
  background: linear-gradient(135deg, #6EE7B7, #3B82F6);
  color: white;
  padding: 40px 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

h1 {
  text-transform: uppercase;
  font-size: 3rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.container {
  border-top: 5px solid rgba(59, 130, 246, 0.8);
  background: rgba(255,255,255,0.95);
  color: #333;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.dropdown {
  width: 100%;
  padding: 15px;
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 8px;
  border: 2px solid #3B82F6;
  margin-bottom: 1.5rem;
  background-color: #f9f9f9;
  appearance: none;
  outline: none;
  transition: border-color 0.3s;
}

.dropdown:focus {
  border-color: #2563EB;
}

.next-button {
  background: linear-gradient(135deg, #3B82F6, #2563EB);
  border: none;
  color: #fff;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
  margin-top: 30px;
}

.next-button:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(59, 130, 246, 0.4);
  background: linear-gradient(135deg, #2563EB, #3B82F6);
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  .container {
    padding: 1.5rem;
  }

  .next-button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}
</style>
