import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fe96351e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showArrow)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.to,
          class: "arrow-link"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'arrow-left'],
              class: "arrow-icon"
            })
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_link, {
      to: "/",
      class: "home-link"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'home'],
          class: "home-icon"
        })
      ]),
      _: 1
    })
  ]))
}